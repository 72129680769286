///
/// Massively by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Image */

	.image {
		border: 0;
		border-radius: _size(border-radius);
		display: inline-block;
		position: relative;

		img {
			border-radius: _size(border-radius);
			display: block;
		}

		&.left,
		&.right {
			max-width: 40%;

			img {
				width: 100%;
			}
		}

		&.left {
			float: left;
			margin: 0 2rem 2rem 0;
			top: 0.75rem;
		}

		&.right {
			float: right;
			margin: 0 0 2rem 2rem;
			top: 0.75rem;
		}

		&.fit {
			display: block;
			margin: (_size(element-margin) * 1.25) 0;
			width: 100%;

			&:first-child {
				margin-top: 0;
			}

			img {
				width: 100%;
			}
		}

		&.main {
			display: block;
			margin: (_size(element-margin) * 1) 0;
			width: 100%;

			&:first-child {
				margin-top: 0;
			}

			img {
				width: 100%;
			}
		}

		@include breakpoint(small) {
			&.fit {
				margin: _size(element-margin) 0;
			}

			&.main {
				margin: _size(element-margin) 0;
			}
		}
	}


.wp-caption {
    @extend .image
}

.wp-caption.alignleft {
    @extend .image.left;
}

.wp-caption.alignright {
    @extend .image.right;
}

.wp-caption.alignnone {
    @extend .image.fit
}

figcaption {
    @extend small;
}

.wp-caption.alignleft img, .wp-caption.alignright img, .content .wp-caption.alignnone img  {
    width: 100%;
}

figure.alignnone {
    width: auto !important;
}

.wp-caption img {
    display: block;
    height: auto;
}


	a.image {
		overflow: hidden;

		img {
			@include vendor('transition', 'transform #{_duration(transition)} ease-out');
		}

		&:hover {
			img {
				@include vendor('transform', 'scale(1.05)');
			}
		}
	}

@media screen and (max-width: 360px) {
    .hide_small {
        display: none;
    }
}
