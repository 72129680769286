figure.gallery-item {
    display: inline-block;
    vertical-align: top;
}

.gallery-icon {
    padding: 0 3px 0 3px;
}

.gallery-icon a {
    border: none;
}

.gallery-icon.landscape img {
    width: 100%;
    height: auto;
}

@media screen and (min-width: 520px) {
    .gallery-columns-3 figure.gallery-item  {
        width: 49%;
    }
}

@media screen and (min-width: 650px) {
    .gallery-columns-3 figure.gallery-item  {
        width: 33.1%;
    }
}
